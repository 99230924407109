import React, { useMemo } from "react";

import {permissions as permissionsList} from "constants/constants";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { Organization } from "types/Organization";
import {
  getFeaturesByOrgId,
  getFromLocalStorage, getOrganizationByName,
  getPermissionsByOrgId,
} from "utils/localStorage";

interface FeatureGateProps {
  name?: string;
  names?: string[];
  fallback?: React.ReactNode; // if desired, render another component
  children: React.ReactNode;
}

export const FeatureGate: React.FC<FeatureGateProps> = ({
  name,
  names,
  fallback,
  children,
}: FeatureGateProps): JSX.Element => {
  const { selectedOrganizationId } = useSelectedOrganization();

  const keys = useMemo(() => {
    if (!names) {
      return name ? [name] : [];
    }
    return names;
  }, [name, names]);

  const foundMatch = useMemo(() => {const skaiOrg = getOrganizationByName('SkAI');
    if(skaiOrg) {
      const isSuperAdmin = skaiOrg.permissions?.find((item) => item === permissionsList.SUPER_ADMIN);
      if (isSuperAdmin) return true;
    }

    const permissions = getPermissionsByOrgId(selectedOrganizationId);
    const features = getFeaturesByOrgId(selectedOrganizationId);

    let match = false;
    keys.forEach((key) => {
      if (permissions?.find((permission) => permission === key)) match = true;
      if (features?.find((feature) => feature === key)) match = true;
    });
    return match;
  }, [selectedOrganizationId, keys]);

  return <>{foundMatch ? children : fallback || null}</>;
};